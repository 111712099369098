<template>
    <!-- add new city -->   
    <teleport to='#modal'>
        <div v-if="cityModal" class="w-full bg-gray-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 text-gray-600">
            <div :class="{ 'scale-out-center': leaving_modal }" class="scale-in-center bg-brown-100 px-4 py-5 rounded-md text-center">
                <form class="">
                    <div class="w-full bg-brown-200 py-1.5 rounded-t">
                        <p for="" class="w-full text-white">City Name</p>
                    </div>
                    <div class="gap-4">
                        <div class="form-group mb-6">
                            <input type="text" v-model="name" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded-b transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brown-100 focus:outline-none focus:ring-1 focus:ring-brown-100" aria-describedby="name">
                        </div>                                                        
                    </div>  
                    <div class="w-full bg-brown-200 py-1.5 rounded-t">
                        <p for="" class="w-full text-white">Select State</p>
                    </div>
                    <div class="gap-4">
                        <select v-model="state_id" class="w-full mb-6 form-select appearance-none block text-base py-2 font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300
                            rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none focus:border-white">
                            <option v-for="state in states" :key="state.id" :value="state.id" class="w-full leading-4">
                                {{ state.name }}
                            </option>
                        </select>                                                        
                    </div>                    
                </form>
                <button @click="leaving_modal = true, turnOffAddModal()" class="bg-red-500 px-4 py-2 rounded-md text-md text-white">Cancel</button>
                <button @click="addCity()" class="bg-brown-default px-7 py-2 ml-2 rounded-md text-md text-white font-semibold">Save</button>
            </div>
        </div>
    </teleport>
    <!-- End of new city -->

    <div class="p-4">
        <top-search class="relative"></top-search>

        <div class="w-full flex justify-between my-4 p-4">
            <label for="charges" class="font-semibold capitalize">Cities</label>
            <div class="flex justify-between">
                <button class="px-5 py-2 rounded-lg hover:outline">
                    Export
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
                <button @click="leaving_modal = false, cityModal = true" class="ml-4 bg-brown-default text-gray-50 px-5 py-2 rounded-lg">Add City</button>
            </div>
        </div>

        <div class="w-full p-5">
            <div class="bg-white flex justify-between p-4">
                <div>
                    <input type="text" placeholder="Search" v-model="search" class="ml-2 outline-none py-1 px-2 text-md border-2 rounded-md">
                </div>
                
                <div>
                    <input type="date" class="ml-2 outline-none py-1 px-2 text-md border-2 rounded-md">
                </div>
            </div>
        </div>

        <!-- <div class="flex">
            <button @click="cityModal = true" class="capitalize py-2 px-3 bg-pink-400 hover:bg-pink-400 transition self-end">new</button>
        </div> -->

        <div v-if="cities" class="w-full my-12 px-4">
            <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1 ">
                            <h3 class="font-semibold text-lg">Cities</h3>
                        </div>
                    </div>
                </div>
                <div class="block w-full overflow-x-auto ">
                    <table class="items-center w-full bg-transparent border-collapse">
                        <thead>
                            <tr>
                                <th class="px-4 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">#</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Name</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">State </th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Date Created</th>
                                <!-- <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Actions</th> -->
                            </tr>
                        </thead>

                        <tbody>
                            <tr class="bg-white" v-for="(city, index) in filteredCities()" :key="index">
                                <td class="border-t-0 rounded-md pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                    <span class="font-bold"> {{ index + 1 }} </span>
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ city.name }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ city.state.name }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ moment(city.created_at).format('DD-MMM-YYYY') }}</td>
                                <!-- <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                    <button>On</button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <square-grid v-else class="absolute top-1/2 left-1/2"></square-grid>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TopSearch from '@/components/TopSearch.vue'
import SquareGrid from '@/components/loaders/SquareGrid.vue'
import Swal from 'sweetalert2'
import moment from 'moment'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export default {
    name: 'City',
    components: {
        TopSearch,
        SquareGrid,
        
    },
    data() {
        return {
            search: '',
            cityModal: false,
            state_id: '',
            name: '',
            dropdown: false,
            leaving_modal: false,
            moment: moment
        }
    },
    computed: {
        ...mapGetters({
            cities: 'citiesModule/getCities', 
            states: 'statesModule/getAvailableStates',
            created: 'citiesModule/getCreated'
        })
    },
    methods: {
        addCity() {
            this.$store.dispatch('citiesModule/cityStoreRequest', { state_id: this.state_id, name: this.name })
            .then(() => { 
                if (this.created == 'success') {
                    Toast.fire({
                        icon: 'success',
                        title: 'City was added successfully!',
                    })
                    this.cityModal = false
                } 
                if(this.error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Unable to complete this request',
                        text: this.error.message
                    })                    
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "An error occured..."
                })
                
            })
            
        },
        logout() {
            this.$store.dispatch('authModule/logoutRequest')
            
            this.$router.push({name: 'Login'})
            
        },
        turnOffAddModal() {
            setTimeout(() => {
                this.cityModal = false;
            }, 500)
            
        },
        filteredCities () {
            return this.cities.filter(city => {
                return city.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1,
                    city.state.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 
            })
        }
    }
}
</script>


<style scoped>
    .scale-in-center {
        -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    .scale-out-center {
        -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }

    @-webkit-keyframes scale-out-center {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
    }

    @keyframes scale-out-center {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
    }


</style>
